<template>
  <v-main>
    <v-container>
      <v-row style="gap: 10vh; margin-top: 5vh" dense>
        <v-col
          class="d-flex aligin-center justify-center"
          cols="12"
          md="4"
          sm="6"
        >
          <v-card style="background-color: transparent" elevation="0">
            <v-card-text
              style="background-color: transparent"
              class="d-flex align-center justify-center"
            >
              <v-row
                class="slide-left-to-right"
                align="center"
                justify="center"
                style="background-color: #f1f1f4 !important"
              >
                <div>
                  <v-col class="d-flex align-center justify-start">
                    <h1 class="gradient" style="line-height: 44px">
                      Qual seu objetivo?
                    </h1>
                  </v-col>
                  <v-col
                    cols="12"
                    md="12"
                    class="d-flex align-center justify-start mt-2"
                  >
                    <div style="background-color: transparent !important">
                      <p
                        class="poppins"
                        style="
                          text-align: justify;
                          text-justify: inter-word;
                          background-clip: text;
                        "
                      >
                        O nosso é preparar você para falar inglês com confiança
                        em qualquer situação. Experimente o método Pitch:
                      </p>
                    </div>
                  </v-col>
                  <v-col
                    cols="12"
                    md="12"
                    class="d-flex align-center justify-start mt-2"
                  >
                    <div class="text-start">
                      <v-btn
                        rounded
                        large
                        color="primary "
                        style="
                          font-size: 18px;
                          font-weight: bolder;
                          text-transform: none;
                        "
                        @click="openLink(pitchWhatsApp)"
                        >Agendar aula gratuita!</v-btn
                      >
                    </div>
                  </v-col>
                </div>
              </v-row></v-card-text
            >
          </v-card>
        </v-col>
        <v-col cols="12" md="7" sm="4">
          <v-img
            class="slide-right-to-left"
            style="background-color: #f1f1f4 !important"
            contain
            lazy
            height="45vh"
            src="@/assets/images/lets_talk-min.webp"
          ></v-img>
        </v-col>
      </v-row>

      <v-row style="margin-top: 5vh">
        <v-col cols="12">
          <v-row>
            <v-col
              cols="12"
              md="4"
              sm="4"
              v-for="(card, index) in infoCards"
              :key="index"
            >
              <v-card
                elevation="1"
                class="infoCard slide-bottom-to-top"
                style="border-radius: 15px"
              >
                <v-card-text style="min-height: 12vh">
                  <v-row dense class="align-center justify-center">
                    <v-col cols="3" class="d-flex align-center justify-center">
                      <v-img contain :src="card.icon" height="8vh"></v-img>
                    </v-col>
                    <v-col cols="9" class="text-center text-md-left pl-2">
                      <h5 class="primary clipedText">{{ card.title }}</h5>
                      <span
                        style="
                          color: #7c7c7f;
                          font-size: 14px;
                          line-height: 20px;
                        "
                        >{{ card.subtitle }}</span
                      >
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
  </v-main>
</template>

<script>
export default {
  name: "MethodologyPage",
  data() {
    return {
      pitchWhatsApp: `https://api.whatsapp.com/send?phone=${this.whatsApp()}&text=Ol%C3%A1%21+Vim+pelo+site+do+curso+e+gostaria+de+agendar+uma+aula+gratuita%21`,
      infoCards: [
        {
          icon: require("@/assets/icons/likes.svg"),
          title: "98,8% satisfeitos",
          subtitle: "Nível de confiança dos alunos",
        },
        {
          icon: require("@/assets/icons/chart.svg"),
          title: "60% de expansão",
          subtitle: "Evolução no último ano",
        },
        {
          icon: require("@/assets/icons/map.svg"),
          title: "+9 estados do país",
          subtitle: "Abrangência nacional",
        },
      ],
    };
  },
  methods: {
    openLink(link) {
      window.open(link, "_blank");
    },
    whatsApp() {
      return process.env.VUE_APP_PITCH_WPP.replaceAll(" ", "").replaceAll(
        "-",
        ""
      );
    },
  },
};
</script>

<style scoped>
.poppins {
  font-family: "Poppins", sans-serif !important;
  line-height: 24px !important;
  font-size: 18px !important;
}

.clipedText {
  background-clip: text;
  color: transparent;
}

.gradient {
  font-size: 36px;
  background: linear-gradient(to right, #e8276f, #9923cd);
  background-clip: text;
  -webkit-text-fill-color: transparent;
}
</style>
