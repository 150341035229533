<template>
  <v-footer class="background-footer" elevation="0">
    <v-container
      :style="{
        'margin-top': $vuetify.breakpoint.smAndDown ? '650px' : '250px',
      }"
    >
      <v-row dense style="margin-top: 30vh">
        <v-col cols="12" md="3">
          <v-row justify="center" align="center">
            <v-col cols="12" class="d-flex align-center justify-center">
              <v-img
                contain
                max-width="8vh"
                src="@/assets/images/logo_pitch_white.png"
              />
            </v-col>
            <v-col cols="12" class="d-flex align-center justify-center">
              <span
                class="body-text white--text text-center"
                style="font-weight: bold; margin-left: 10px"
              >
                Nossa metodologia, <br />seu sucesso!
              </span>
            </v-col>
            <!-- Redes sociais -->
            <v-col cols="12" class="d-flex align-center justify-center mt-3">
              <v-btn icon dark @click="openLink(linkedin)">
                <v-icon large class="mr-2">mdi-linkedin</v-icon>
              </v-btn>
              <v-btn icon dark @click="openLink(twitter)">
                <v-icon large class="mr-2">mdi-twitter</v-icon>
              </v-btn>
              <v-btn icon dark @click="openLink(instagram)">
                <v-icon large class="mr-2">mdi-instagram</v-icon>
              </v-btn>
              <v-btn icon dark @click="openLink(facebook)">
                <v-icon large class="mr-2">mdi-facebook</v-icon>
              </v-btn>
              <v-btn icon dark @click="openLink(youtube)">
                <v-icon large class="mr-2">mdi-youtube</v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-col>

        <!-- Segunda coluna -->
        <v-col cols="12" md="3">
          <v-divider
            v-if="$vuetify.breakpoint.xsOnly"
            class="mt-2 mb-2"
            dark
          ></v-divider>

          <v-row justify="center" align="center">
            <v-col cols="12" class="d-flex align-center justify-center">
              <span
                class="white--text text-center bolder--text body-text"
                style="font-weight: bold; font-size: 14px"
              >
                Contato
              </span>
            </v-col>
            <v-col cols="12" class="d-flex align-center justify-center">
              <v-icon dark class="mr-2">mdi-phone-in-talk</v-icon>
              <span class="white--text">{{ whatsApp }}</span>
            </v-col>
            <v-col cols="12" class="d-flex align-center justify-center">
              <v-icon dark class="mr-2">mdi-email-outline</v-icon>
              <span class="white--text">pitchclasses@pitchenglish.com</span>
            </v-col>
            <v-col cols="12" class="d-flex align-center justify-center">
              <v-icon dark class="mr-2">mdi-at</v-icon>
              <span class="white--text">pitch_english</span>
            </v-col>
          </v-row>
        </v-col>

        <!-- Terceira coluna -->
        <v-col cols="12" md="3">
          <v-divider
            v-if="$vuetify.breakpoint.xsOnly"
            class="mt-2 mb-2"
            dark
          ></v-divider>
          <v-row justify="center" align="center">
            <v-col cols="12" class="d-flex align-center justify-center">
              <span
                class="white--text text-center bolder--text"
                style="font-weight: bold; font-size: 14px"
              >
                Políticas
              </span>
            </v-col>
            <v-col
              cols="12"
              class="d-flex align-center justify-center"
              @click="gotoPolicies"
            >
              <a><span class="white--text">Termos & Condições</span></a>
            </v-col>
            <v-col
              cols="12"
              class="d-flex align-center justify-center"
              @click="gotoPolicies"
            >
              <a><span class="white--text">Políticas de privacidade</span></a>
            </v-col>
          </v-row>
        </v-col>

        <!-- Quarta coluna -->
        <v-col cols="12" md="3">
          <v-divider
            v-if="$vuetify.breakpoint.xsOnly"
            class="mt-2 mb-2"
            dark
          ></v-divider>
          <v-row justify="center" align="center">
            <v-col cols="12" class="d-flex align-center justify-center">
              <span
                class="white--text text-center bolder--text"
                style="font-weight: bold; font-size: 14px"
              >
                Menu
              </span>
            </v-col>
            <!-- Menu de navegação -->
            <v-col
              v-for="(menu, index) in menuItems"
              :key="index"
              class="ma-3 pa-1"
              cols="12"
              style="margin-top: 5vh"
            >
              <a
                ><span
                  @click="scrollToSection(menu.section)"
                  class="white--text text-center d-flex align-start justify-center"
                >
                  {{ menu.name }}
                </span>
              </a>
            </v-col>
          </v-row>
        </v-col>
      </v-row>

      <!-- Linha de divisão -->
      <v-divider
        style="
          margin-top: 6vh;
          border-width: 2px;
          border-color: #ffffff;
          border-radius: 10px;
        "
        dark
      ></v-divider>

      <!-- Direitos autorais -->
      <v-row dense align="center" justify="center" style="margin-top: 5vh">
        <span class="white--text"
          >© 2024 Pitch English. All Rights Reserved.</span
        >
      </v-row>
    </v-container>
  </v-footer>
</template>

<script>
export default {
  name: "FooterPage",
  computed: {
    whatsApp() {
      return process.env.VUE_APP_PITCH_WPP;
    },
  },
  data() {
    return {
      menuItems: [
        {
          name: "Metodologia",
          section: "metodologia",
        },
        {
          name: "Diferencial",
          section: "diferencial",
        },
        {
          name: "Planos",
          section: "planos",
        },
        {
          name: "FAQ",
          section: "faq",
        },
      ],
      linkedin: "https://www.linkedin.com/company/pitch-english",
      twitter: "https://twitter.com/pitch_english",
      instagram: "https://www.instagram.com/pitch_english/",
      facebook:
        "https://www.facebook.com/profile.php?id=100079214842188&mibextid=LQQJ4d",
      youtube: "https://www.youtube.com/@PitchEnglish",
    };
  },
  methods: {
    scrollToSection(section) {
      this.$emit("scrollTo", section);
    },
    gotoPolicies() {
      if (this.$route.path !== "/termos")
        this.$router.push({
          path: "/termos",
        });
    },
    openLink(link) {
      window.open(link, "_blank");
    },
  },
};
</script>

<style scoped>
.background-footer {
  background-image: url("@/assets/images/footer/footer.png");
  background-size: cover;
  background-position: center;
  background-color: transparent !important;
}
</style>
