import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import i18n from "./plugins/i18n";
import "typeface-poppins";

import VCurrencyField from "v-currency-field";

import GradientBar from "@/components/GradientBar.vue";
import CustomFileInput from "./components/form/CustomFileInput.vue";
import CustomAutoComplete from "@/components/form/CustomTextField.vue";
import CustomMaskedField from "./components/form/CustomMaskedField.vue";
import CustomCountrySelector from "@/components/form/CustomCountrySelect.vue";
import CustomDebouncedTextField from "./components/form/CustomDebouncedTextField.vue";

Vue.component("gradient-bar", GradientBar);
Vue.component("money-input", VCurrencyField);
Vue.component("custom-input", CustomAutoComplete);
Vue.component("custom-file-input", CustomFileInput);
Vue.component("custom-masked-input", CustomMaskedField);
Vue.component("debounced-input", CustomDebouncedTextField);
Vue.component("custom-country-selector", CustomCountrySelector);

Vue.config.productionTip = false;

Vue.use(VCurrencyField, {
  locale: "pt-BR",
  decimalLength: 2,
  autoDecimalMode: true,
  min: 0,
  max: null,
  defaultValue: 0,
  valueAsInteger: false,
  allowNegative: false,
});

new Vue({
  router,
  store,
  vuetify,
  i18n,
  render: (h) => h(App),
}).$mount("#app");
