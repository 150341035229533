import Vue from "vue";
import Vuex from "vuex";
import createPersistedState from "vuex-persistedstate";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    user: null,
    token: null,
    refreshToken: null,
    module: null,
  },
  getters: {
    token: ({ token }) => token,
    user: ({ user }) => user,
    refreshToken: ({ refreshToken }) => refreshToken,
    module: ({ module }) => module,
  },
  mutations: {
    SET_USER(state, payload) {
      state.user = payload;
      localStorage.setItem("user", payload);
    },
    SET_TOKEN(state, payload) {
      state.token = payload;
      localStorage.setItem("token", payload);
    },
    SET_REFRESH_TOKEN(state, payload) {
      state.token = payload;
      localStorage.setItem("refreshToken", payload);
    },
    SET_MODULE(state, payload) {
      state.module = payload;
    },
  },
  actions: {
    setSignIn({ dispatch }, payload) {
      dispatch("setUser", payload.user);
      dispatch("setToken", payload.token);
      dispatch("setRefreshToken", payload.refreshToken);
      dispatch("setModule", payload.module);
    },
    setUser({ commit }, payload) {
      commit("SET_USER", payload);
    },
    setToken({ commit }, payload) {
      commit("SET_TOKEN", payload);
    },
    setRefreshToken({ commit }, payload) {
      commit("SET_REFRESH_TOKEN", payload);
    },
    setModule({ commit }, payload) {
      commit("SET_MODULE", payload);
    },
  },
  plugins: [createPersistedState({ key: "session" })],
});
