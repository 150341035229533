<template>
  <div>
    <TopMenu @scroll="scroll" />
    <v-container style="margin-top: 150px">
      <v-row dense>
        <v-col cols="12" md="6" sm="6">
          <v-col
            cols="12"
            md="10"
            sm="10"
            class="d-flex align-center justify-start pb-0"
          >
            <h5 class="gradient bolder" style="font-size: 18px">Em breve:</h5>
          </v-col>
          <v-col
            cols="12"
            md="12"
            sm="12"
            class="d-flex align-center justify-start mt-0 pt-0"
          >
            <h2 class="gradient bolder">Seja um professor licenciado</h2>
          </v-col>

          <v-col
            cols="12"
            md="10"
            sm="10"
            class="d-flex align-center justify-start ma-0 pa-0"
          >
            <span class="ml-4">
              Professores, fiquem atentos para mais detalhes sobre como
              tornar-se franqueado e ampliar sua visão educacional conosco. Em
              breve, o Pitch abrirá as portas para novas oportunidades de
              franquia!
            </span>
          </v-col>
        </v-col>
        <v-col
          cols="12"
          md="6"
          sm="6"
          class="d-flex align-center jutify-center"
        >
          <v-img src="@/assets/images/building.png" />
        </v-col>
      </v-row>
    </v-container>
    <Footer @scrollTo="scroll" />
  </div>
</template>
<script>
import TopMenu from "@/components/homepage/TopMenu.vue";
import Footer from "@/components/homepage/Footer.vue";
export default {
  name: "PartnerPage",
  components: {
    TopMenu,
    Footer,
  },
  mixins: [],
  props: {},
  data() {
    return {};
  },
  computed: {},
  watch: {},
  mounted() {
    scrollTo(0, 0);
  },
  methods: {
    scroll() {
      this.$router.push({ path: "/" });
    },
  },
};
</script>
<style scoped>
.bolder {
  font-weight: bold;
}

.gradient {
  font-size: 36px;
  background: linear-gradient(to right, #e8276f, #9923cd);
  background-clip: text;
  -webkit-text-fill-color: transparent;
}
</style>
