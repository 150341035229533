<template>
  <v-container>
    <v-row dense>
      <v-col cols="auto">
        <gradient-bar />
        <h6 class="primary clipedText mt-4" style="font-size: 19.2">
          Diferencial
        </h6>
      </v-col>
    </v-row>
    <v-row
      style="gap: 10vh; background-color: transparent !important"
      dense
      class="slide-left-to-right"
    >
      <v-col cols="12" md="6" sm="12">
        <h3 class="clipedText primary">Experiência de aprendizado única</h3>
      </v-col>
    </v-row>
    <v-row
      style="gap: 10vh; background-color: transparent !important"
      dense
      class="slide-left-to-right"
    >
      <v-col>
        <span class="poppins; body-text" style="background-clip: text">
          No Pitch, nossos professores são certificados internacionalmente pela
          ETS TOEFL, Cambridge e IELTS
        </span>
        <br />
        <div class="mt-6">
          <span class="poppins body-text" style="background-clip: text">
            Mais do que fluência, trazem consigo vivências culturais únicas,
            proporcionando orientação personalizada para alcançar objetivos como
            viagens, estudos, trabalho ou lazer. Faça parte dos alunos que
            mandaram muito bem no inglês com a ajuda de nossos excepcionais
            professores.
          </span>
        </div>
      </v-col>

      <v-col cols="12" md="6" sm="12" v-if="$vuetify.breakpoint.mdAndUp">
        <v-card elevation="0" style="background-color: transparent">
          <v-card-text style="min-height: 20vh">
            <v-row dense justify="center">
              <v-col
                :cols="$vuetify.breakpoint.mdAndUp ? '12' : null"
                :md="$vuetify.breakpoint.mdAndUp ? '9' : null"
              >
                <v-img
                  src="@/assets/images/differential/teacher-min.png"
                  style="border-radius: 5px"
                  lazy
                  height="30vh"
                  contain
                ></v-img>
              </v-col>
              <v-col
                :cols="$vuetify.breakpoint.mdAndUp ? '12' : null"
                :md="$vuetify.breakpoint.mdAndUp ? '3' : null"
              >
                <v-row dense>
                  <v-img
                    lazy
                    src="@/assets/images/differential/teacher_mini_1-min.png"
                    contain
                    height="10vh"
                  ></v-img>
                </v-row>
                <v-row dense class="mt-2">
                  <v-img
                    lazy
                    src="@/assets/images/differential/teacher_mini_2-min.png"
                    contain
                    height="10vh"
                  ></v-img>
                </v-row>
                <v-row dense class="mt-2">
                  <v-img
                    lazy
                    src="@/assets/images/differential/teacher_mini_3-min.png"
                    contain
                    height="10vh"
                  ></v-img>
                </v-row>
              </v-col>
            </v-row>

            <v-row dense class="mt-2" v-if="$vuetify.breakpoint.smAndDown">
              <v-col class="d-flex align-center justify-center">
                <img
                  :height="$vuetify.breakpoint.xs ? '25vh' : mini"
                  lazy
                  src="@/assets/images/differential/TOEFL-min.png"
                />
              </v-col>
              <v-col class="d-flex align-center justify-center">
                <img
                  :height="mini"
                  lazy
                  src="@/assets/images/differential/cambridge-min.png"
                />
              </v-col>
              <v-col class="d-flex align-center justify-center">
                <img
                  :height="mini"
                  lazy
                  src="@/assets/images/differential/IELTS-min.png"
                />
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-row
      dense
      justify="center"
      style="gap: 10vh; background-color: transparent !important"
      class="slide-left-to-right"
    >
      <v-col>
        <span class="primary clipedText button-text"
          >Veja o que dizem os alunos sobre a experiência no Pitch !</span
        >
      </v-col>
      <v-col
        class="d-flex align-start justify-center"
        v-if="$vuetify.breakpoint.mdAndUp"
      >
        <v-row>
          <v-col class="d-flex align-center justify-center">
            <img
              :height="$vuetify.breakpoint.xs ? '25vh' : mini"
              lazy
              src="@/assets/images/differential/TOEFL-min.png"
            />
          </v-col>
          <v-col class="d-flex align-center justify-center">
            <img
              :height="mini"
              lazy
              src="@/assets/images/differential/cambridge-min.png"
            />
          </v-col>
          <v-col class="d-flex align-center justify-center">
            <img
              :height="mini"
              lazy
              src="@/assets/images/differential/IELTS-min.png"
            />
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row
      style="gap: 10vh; background-color: transparent !important"
      class="mt-4 slide-right-to-left"
      v-if="$vuetify.breakpoint.mdAndUp"
    >
      <v-carousel hide-delimiters>
        <v-carousel-item
          v-for="(item, i) in evidences"
          :key="i"
          reverse-transition="fade-transition"
          transition="fade-transition"
        >
          <v-row dense>
            <v-col class="d-flex align-center justify-center" cols="12" md="6">
              <v-card
                elevation="1"
                style="background-color: transparent"
                max-width="75vh"
              >
                <v-card-text>
                  <v-row dense align="start">
                    <v-col cols="12" md="1">
                      <v-img
                        src="@/assets/icons/quotes.svg"
                        height="2vh"
                        contain
                      ></v-img>
                    </v-col>
                  </v-row>
                  <v-row style="min-height: 25vh">
                    <v-col
                      cols="12"
                      md="11"
                      class="d-flex align-center justify-center ml-10 my-10"
                      style="text-overflow: ellipsis"
                    >
                      <span class="poppins" style="background-clip: text">{{
                        item[0].text
                      }}</span>
                    </v-col>
                  </v-row>
                </v-card-text>
                <v-card-actions class="ma-0 pa-0">
                  <v-row class="d-flex align-center justify-end ma-0 pa-0">
                    <v-col
                      class="flex-column justify-end"
                      cols="12"
                      md="auto"
                      sm="auto"
                    >
                      <v-row justify="end">
                        <h6 class="primary clipedText">
                          {{ item[0].name }}
                        </h6>
                      </v-row>
                      <v-row justify="end"
                        ><span
                          class="primary clipedText body-text-regular"
                          style="font-size: 14px !important"
                        >
                          {{ item[0].address }}
                        </span></v-row
                      >
                    </v-col>
                    <v-col cols="auto" class="d-flex align-center">
                      <v-img
                        lazy
                        contain
                        height="55px"
                        :src="item[0].avatar"
                      ></v-img>
                    </v-col>
                  </v-row>
                </v-card-actions>
              </v-card>
            </v-col>
            <v-col
              cols="12"
              md="6"
              sm="auto"
              class="d-flex align-center justify-center"
            >
              <v-card style="background-color: transparent" max-width="75vh">
                <v-card-text>
                  <v-row dense>
                    <v-col
                      cols="12"
                      md="1"
                      sm="1"
                      class="d-flex algin-center justify-center"
                    >
                      <v-img
                        src="@/assets/icons/quotes.svg"
                        height="2vh"
                        contain
                      ></v-img>
                    </v-col>
                  </v-row>
                  <v-row style="min-height: 25vh">
                    <v-col
                      cols="12"
                      md="11"
                      class="d-flex align-center justify-center ml-10 my-10"
                      style="text-overflow: ellipsis"
                    >
                      <span class="poppins" style="background-clip: text">{{
                        item[1].text
                      }}</span>
                    </v-col>
                  </v-row>
                </v-card-text>
                <v-card-actions class="mb-0 pb-0">
                  <v-row class="d-flex align-center justify-end ma-0 pa-0">
                    <v-col
                      class="flex-column justify-end"
                      cols="12"
                      md="auto"
                      sm="auto"
                    >
                      <v-row justify="end">
                        <h6 class="primary clipedText">
                          {{ item[1].name }}
                        </h6>
                      </v-row>
                      <v-row justify="end"
                        ><span
                          class="primary clipedText body-text-regular"
                          style="font-size: 14px !important"
                          >{{ item[1].address }}</span
                        ></v-row
                      >
                    </v-col>
                    <v-col cols="auto" class="d-flex align-center">
                      <v-img
                        contain
                        height="55px"
                        :src="item[1].avatar"
                      ></v-img>
                    </v-col>
                  </v-row>
                </v-card-actions>
              </v-card>
            </v-col>
          </v-row>
        </v-carousel-item>
      </v-carousel>
    </v-row>
    <v-row dense class="mt-4" v-else>
      <v-carousel hide-delimiters>
        <v-carousel-item
          v-for="(item, i) in evidencesMobile"
          :key="i"
          reverse-transition="fade-transition"
          transition="fade-transition"
        >
          <v-row dense>
            <v-col cols="12" sm="12" class="d-flex align-center justify-center">
              <v-card
                elevation="1"
                style="background-color: transparent"
                max-width="75vh"
              >
                <v-card-text min-heigth="380px" max-height="500px">
                  <v-row>
                    <v-col
                      cols="12"
                      md="1"
                      sm="1"
                      class="d-flex algin-center justify-center"
                    >
                      <v-img
                        src="@/assets/icons/quotes.svg"
                        height="2vh"
                        contain
                      ></v-img>
                    </v-col>
                  </v-row>
                  <v-row style="min-height: 25vh">
                    <v-col
                      cols="12"
                      md="11"
                      class="d-flex align-center justify-center"
                      style="text-overflow: ellipsis"
                    >
                      <span class="poppins" style="background-clip: text">{{
                        item.text
                      }}</span>
                    </v-col>
                  </v-row>
                </v-card-text>
                <v-card-actions>
                  <v-row class="d-flex align-center justify-end ma-0 pa-0">
                    <v-col
                      class="flex-column justify-center"
                      cols="12"
                      md="auto"
                      sm="auto"
                    >
                      <v-row justify="end">
                        <h6 class="primary clipedText">
                          {{ item.name }}
                        </h6>
                      </v-row>
                      <v-row justify="end"
                        ><span
                          class="primary clipedText body-text-regular"
                          style="font-size: 14px !important"
                        >
                          {{ item.address }}
                        </span></v-row
                      >
                    </v-col>
                    <v-col cols="auto" class="d-flex align-center">
                      <v-img
                        lazy
                        contain
                        height="55px"
                        :src="item.avatar"
                      ></v-img>
                    </v-col>
                  </v-row>
                </v-card-actions>
              </v-card>
            </v-col>
          </v-row>
        </v-carousel-item>
      </v-carousel>
    </v-row>
  </v-container>
</template>
<script>
export default {
  name: "DiferentialSection",
  components: {},
  mixins: [],
  props: {},
  computed: {
    screenSize() {
      const size = window.innerWidth;
      if (size >= 960) return "280px";
      else if (size >= 768 && size < 960) return "200px";
      else return "250px";
    },
    mini() {
      const size = window.innerWidth;

      if (size >= 960 && size <= 1280) return "35px";
      else if (size >= 768 && size < 959) return "30px";
      else return "25px";
    },
  },
  watch: {},
  mounted() {
    if (window.innerWidth < 960) this.isMobile = true;
  },
  methods: {
    selectButton(value) {
      this.selected = value;
    },
  },
  data() {
    return {
      selected: 1,
      isMobile: false,
      evidences: [
        [
          {
            name: "José Alício",
            address: "Cataguases/MG",
            avatar: require("@/assets/avatars/Alicio.svg"),
            text: "Sempre tive o inglês presente, mas faltava confiança para me destacar no mercado. O curso é direto ao ponto, focado na conversação e adaptado ao meu ritmo. Agora, o Pitch é meu passaporte para realizar o sonho de um intercâmbio. Estou confiante e preparado para conquistar novos horizontes!",
          },
          {
            name: "Paulo Ricardo",
            address: "Três Rios/RJ",
            avatar: require("@/assets/avatars/Ricardo.svg"),
            text: "Desistindo de cursos de inglês caros e monótonos, encontrei o Pitch English. Incrível metodologia, ótimos professores, ambiente descontraído e preço justo. Superei a vergonha de falar inglês e agora vejo portas se abrindo que nem imaginava.",
          },
        ],
        [
          {
            name: "Mayara Lima",
            address: "Juiz de Fora/MG",
            avatar: require("@/assets/avatars/Mayara.svg"),
            text: "Adoro estudar com minha irmã e meu namorado. Sempre que esqueço de fazer uma atividade ou perco a hora da aula, minha irmã está lá para me lembrar. É maravilhoso poder praticar inglês todos os dias com meu amor.",
          },
          {
            name: "Natália Brand",
            address: "Petrópolis/RJ",
            avatar: require("@/assets/avatars/Natalia.svg"),
            text: "Quando comecei o curso, admito que me senti insegura. A abordagem sobre gramática e exercícios parecia um pouco entediante. No entanto, desde então, tenho percebido uma grande evolução. As constantes novidades têm feito toda a diferença no aprendizado. Esta experiência tem sido a melhor que já tive com o inglês!",
          },
        ],
        [
          {
            name: "Beatriz Machado",
            address: "Juiz de Fora/MG",
            avatar: require("@/assets/avatars/Beatriz.svg"),
            text: "Estou no curso de inglês há um ano e meio. Em 2020, conheci o Pitch por várias recomendações positivas e agora sei que elas estavam certas! O Pitch é incrível e tem me ajudado a me apaixonar pelo inglês aos poucos, com ótimos professores e uma metodologia que faz a diferença.",
          },
          {
            name: "Matheus Barbosa",
            address: "Juiz de Fora/MG",
            avatar: require("@/assets/avatars/default.svg"),
            text: `As aulas são incrivelmente divertidas; estou simplesmente adorando! Com essa abordagem descontraída de bate-papo, o tempo passa voando e, quando percebemos, ficamos surpresos, pensando: "Já acabou?"`,
          },
        ],
      ],
      evidencesMobile: [
        {
          name: "José Alício",
          address: "Cataguases/MG",
          avatar: require("@/assets/avatars/Alicio.svg"),
          text: "Sempre tive o inglês presente, mas faltava confiança para me destacar no mercado. O curso é direto ao ponto, focado na conversação e adaptado ao meu ritmo. Agora, o Pitch é meu passaporte para realizar o sonho de um intercâmbio. Estou confiante e preparado para conquistar novos horizontes!",
        },
        {
          name: "Paulo Ricardo",
          address: "Três Rios/RJ",
          avatar: require("@/assets/avatars/Ricardo.svg"),
          text: "Desistindo de cursos de inglês caros e monótonos, encontrei o Pitch English. Incrível metodologia, ótimos professores, ambiente descontraído e preço justo. Superei a vergonha de falar inglês e agora vejo portas se abrindo que nem imaginava.",
        },

        {
          name: "Mayara Lima",
          address: "Juiz de Fora/MG",
          avatar: require("@/assets/avatars/Mayara.svg"),
          text: "Adoro estudar com minha irmã e meu namorado. Sempre que esqueço de fazer uma atividade ou perco a hora da aula, minha irmã está lá para me lembrar. É maravilhoso poder praticar inglês todos os dias com meu amor.",
        },
        {
          name: "Natália Brand",
          address: "Petrópolis/RJ",
          avatar: require("@/assets/avatars/Natalia.svg"),
          text: "Quando comecei o curso, admito que me senti insegura. A abordagem sobre gramática e exercícios parecia um pouco entediante. No entanto, desde então, tenho percebido uma grande evolução. As constantes novidades têm feito toda a diferença no aprendizado. Esta experiência tem sido a melhor que já tive com o inglês!",
        },

        {
          name: "Beatriz Machado",
          address: "Juiz de Fora/MG",
          avatar: require("@/assets/avatars/Beatriz.svg"),
          text: "Estou no curso de inglês há um ano e meio. Em 2020, conheci o Pitch por várias recomendações positivas e agora sei que elas estavam certas! O Pitch é incrível e tem me ajudado a me apaixonar pelo inglês aos poucos, com ótimos professores e uma metodologia que faz a diferença.",
        },
        {
          name: "Matheus Barbosa",
          address: "Juiz de Fora/MG",
          avatar: require("@/assets/avatars/default.svg"),
          text: `As aulas são incrivelmente divertidas; estou simplesmente adorando! Com essa abordagem descontraída de bate-papo, o tempo passa voando e, quando percebemos, ficamos surpresos, pensando: "Já acabou?"`,
        },
      ],
    };
  },
};
</script>
<style scoped>
.headerText {
  font-size: 5vh;
  white-space: normal;
  font-style: bold;
  overflow: visible;
  padding: 5px;
}

.clipedText {
  background-clip: text;
  color: transparent;
}

.menuLinear {
  width: 97px;
  background-color: linear-gradient(to right, #9923cd, #e8276f);
}

.bolder {
  font-weight: bolder;
}

.secondaryText {
  color: "#5FF5F61" !important;
  font-size: 16px !important;
  font-weight: 400 !important;
  white-space: normal !important;
}

.selected {
  width: 30px;
}
</style>
