<template>
  <div class="fill-width">
    <div v-if="!!label" class="text-body-2 mb-1">{{ label }}</div>
    <v-text-field
      solo
      flat
      v-model="value"
      :outlined="outlined"
      v-on="$listeners"
      class="rounded-lg"
      background-color="#F1F3F5"
      :append-icon="appendIcon"
      :placeholder="placeholder"
      :rules="required ? rules : []"
      :readonly="readonly"
      :disabled="disabled"
      :type="type"
      :hide-details="hideDetails ? 'auto' : false"
      :hint="hint"
      :error-messages="errorMessage"
      @click:append="handleAppendIconClick"
      @change="throttledSearch"
    />
  </div>
</template>
<script>
export default {
  name: "custom-input",
  data() {
    return {
      value: "",
    };
  },
  props: {
    currentValue: {
      type: [String, Number],
      default: null,
    },

    label: {
      type: String,
      default: "",
    },

    placeholder: {
      type: String,
      default: "",
    },

    appendIcon: {
      type: String,
      default: "",
    },

    type: {
      type: String,
      default: "text",
    },

    customRule: {
      type: Array,
      default: () => [],
    },

    required: {
      type: Boolean,
      default: true,
    },

    readonly: {
      type: Boolean,
      default: false,
    },

    disabled: {
      type: Boolean,
      default: false,
    },

    hideDetails: {
      type: Boolean,
      default: false,
    },
    hint: {
      type: String,
      default: "",
    },
    errorMessage: {
      type: String,
      default: "",
    },
    outlined: {
      type: Boolean,
      default: false,
    },
  },

  mounted() {
    if (this.currentValue) this.value = this.currentValue;
  },

  computed: {
    rules() {
      return this.customRule.length <= 0
        ? [(v) => !!v || "Campo Obrigatório"]
        : this.customRule;
    },
  },

  methods: {
    handleInput(event) {
      this.value = event;
      this.$emit("input", event);
    },
    handleAppendIconClick() {
      this.$emit("iconClick");
    },
    throttledSearch(event) {
      if (this.searchTimeout !== null) {
        clearTimeout(this.searchTimeout);
      }
      this.searchTimeout = setTimeout(() => {
        if (event) {
          this.$emit("search", event);
        }
        this.searchTimeout = null;
      }, 400);
    },
  },
};
</script>
<style scoped></style>
