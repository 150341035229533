<template>
  <div>
    <div><span>País</span></div>
    <div>
      <v-autocomplete
        class="mt-2"
        :dense="dense"
        outlined
        chips
        background-color="#F1F3F5"
        v-model="selected"
        :items="countries"
        :item-value="`ddi`"
        :item-text="`name`"
        @change="onInput"
      >
        <template v-slot:selection="{ item }">
          <v-chip outlined close @click:close="selected = null">
            {{ item.ddi + " " + item.flag + " " }}
            <span v-if="displayName">{{ item.name }}</span>
          </v-chip>
        </template>
      </v-autocomplete>
    </div>
  </div>
</template>

<script>
import { countries } from "@/assets/data/contries";
export default {
  data() {
    return {
      selected: "55",
      countries: countries,
    };
  },
  props: {
    displayName: { type: Boolean, default: false },
    dense: { type: Boolean, default: true },
  },
  methods: {
    onInput(value) {
      this.$emit("onInput", value);
    },
  },
};
</script>
