import Vue from "vue";
import VueI18n from "vue-i18n";

import br from "../locales/pt-BR.json";
import en from "../locales/en-US.json";

Vue.use(VueI18n);

export default new VueI18n({
  locale: "pt", // define o idioma padrão
  fallbackLocale: "en", // caso o idioma não exista, use o inglês
  messages: {
    pt: br,
    en: en,
  },
});
