<template>
  <v-main>
    <v-container>
      <v-row dense>
        <v-col cols="auto">
          <gradient-bar />
          <h6 class="primary clipedText mt-4">Metodologia</h6>
        </v-col>
      </v-row>

      <v-row style="gap: 10vh" dense>
        <v-col
          cols="12"
          md="6"
          sm="6"
          class="slide-left-to-right"
          style="background-color: #f1f1f4 !important"
        >
          <v-row class="mt-3" dense>
            <h3 class="clipedText primary">
              O inglês sob uma nova perspectiva
            </h3>
          </v-row>
        </v-col>
      </v-row>
      <v-row style="gap: 10vh" algin="center" justify="center">
        <v-col
          cols="12"
          sm="12"
          md="6"
          class="slide-left-to-right"
          style="background-color: #f1f1f4 !important"
          ><span class="poppins" style="background-clip: text">
            Diferentemente do ensino tradicional, nossa metodologia destaca a
            integração de aspectos culturais, a prática constante da lingua em
            contextos reais e um ambiente de aprendizado dinâmico que visa não
            apenas a fluência linguística, mas também uma compreensão profunda
            da língua inglesa.
          </span>
        </v-col>
        <v-col
          class="slide-right-to-left"
          style="background-color: transparent !important"
          v-if="$vuetify.breakpoint.mdAndUp"
        >
          <v-card
            elevation="0"
            style="background-color: transparent !important"
          >
            <v-card-text class="d-flex align-end justify-center">
              <v-row dense>
                <v-col
                  cols="12"
                  md="2"
                  sm="2"
                  xs="auto"
                  class="d-flex align-center justify-start"
                >
                  <v-img
                    @click="updateYear('decrement')"
                    max-width="15"
                    src="@/assets/icons/left_arrow.svg"
                  ></v-img>
                </v-col>
                <v-col cols="10" md="8" sm="8" xs="auto">
                  <div class="d-flex align-center justify-center">
                    <h1 style="font-size: 24px; color: #5f5f61 !important">
                      {{ currentYear }}
                    </h1>
                  </div>
                  <div class="d-flex align-center justify-center">
                    <span style="color: #5f5f61 !important">
                      {{ displayableYears }}
                    </span>
                  </div>
                </v-col>
                <v-col
                  cols="auto"
                  md="2"
                  sm="2"
                  xs="auto"
                  class="d-flex align-center justify-end"
                >
                  <v-img
                    @click="updateYear('increment')"
                    max-width="15"
                    src="@/assets/icons/right_arrow.svg"
                  ></v-img>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <v-row style="gap: 10vh" align="center" justify="center">
        <v-col
          cols="12"
          md="6"
          sm="12"
          class="slide-left-to-right"
          style="background-color: transparent !important"
        >
          <v-img
            class="slide-bottom-to-top studentImg"
            style="border-radius: 10px"
            src="@/assets/images/student-min.webp"
            lazy
            height="30vh"
          ></v-img>
        </v-col>
        <v-col
          class="slide-right-to-left"
          style="background-color: transparent !important"
          v-if="$vuetify.breakpoint.smAndUp"
        >
          <v-card
            elevation="2"
            class="fill-width fill-height"
            style="background-color: transparent !important"
          >
            <v-card-text
              elevation="0"
              style="
                background-color: transparent !important;
                border-radius: 10px;
                min-height: 30vh;
              "
            >
              <v-row>
                <v-col cols="12" md="12" sm="12">
                  <v-row
                    class="slide-right-to-left mt-2"
                    style="background-color: transparent !important"
                  >
                    <v-col
                      cols="12"
                      md="12"
                      sm="12"
                      class="d-flex align-center justify-center"
                    >
                      <v-img
                        max-width="80"
                        :src="currentAnimationItem.image"
                      ></v-img>
                    </v-col>

                    <v-col
                      cols="12"
                      md="12"
                      sm="12"
                      class="d-flex justify-start ml-5 pa-0 mb-0"
                    >
                      <h6 style="color: #5f5f61" class="mt-4 animation-text">
                        {{ currentAnimationItem.title }}
                      </h6>
                    </v-col>
                    <div class="ml-5 mt-4">
                      <span>{{ currentAnimationItem.subtitle }}</span>
                    </div>
                  </v-row>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>

      <v-row dense class="mt-6" style="gap: 10vh">
        <v-col
          class="d-flex align-center justify-center slide-left-to-right"
          style="background-color: transparent !important"
          cols="12"
          sm="12"
          md="6"
        >
          <v-hover>
            <template v-slot="{ hover }">
              <v-card
                :elevation="hover ? 6 : 0"
                style="background-color: transparent !important"
              >
                <v-card-text style="min-height: 150px">
                  <v-row dense>
                    <v-col class="d-flex align-center justify-center">
                      <v-img
                        src="@/assets/icons/Aa.svg"
                        max-width="40px"
                        contain
                      ></v-img>
                    </v-col>
                    <v-col cols="12" md="10" sm="10">
                      <h6 class="clipedText primary text-start button-large">
                        Abordagem comunicativa exclusiva
                      </h6>
                    </v-col>
                  </v-row>
                  <v-row dense>
                    <v-col></v-col>
                    <v-col
                      cols="12"
                      md="10"
                      sm="10"
                      class="d-flex align-center justify-center"
                    >
                      <span
                        class="poppins body-text"
                        style="background-clip: text; font-size: 16px"
                      >
                        No Pitch English, adotamos uma abordagem comunicativa
                        exclusiva da metodologia irlandesa, centrada na prática
                        da língua em situações reais.</span
                      >
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </template>
          </v-hover>
        </v-col>
        <v-col
          class="d-flex align-center justify-center slide-right-to-left"
          style="background-color: transparent !important"
        >
          <v-hover>
            <template v-slot="{ hover }">
              <v-card
                :elevation="hover ? 6 : 0"
                style="background-color: transparent !important"
              >
                <v-card-text style="min-height: 150px">
                  <v-row dense>
                    <v-col class="d-flex align-center justify-center">
                      <v-img
                        src="@/assets/icons/book.svg"
                        max-width="40px"
                        contain
                      ></v-img>
                    </v-col>
                    <v-col cols="12" md="10" sm="10">
                      <h6 class="clipedText primary text-start button-large">
                        Aprendizado contextualizado
                      </h6>
                    </v-col>
                  </v-row>
                  <v-row dense>
                    <v-col></v-col>
                    <v-col
                      cols="12"
                      md="10"
                      sm="10"
                      class="d-flex align-center justify-center"
                    >
                      <span
                        class="poppins"
                        style="background-clip: text; font-size: 16px"
                      >
                        Nossa metodologia visa não apenas à compreensão
                        gramatical, mas também à aplicação prática da língua em
                        contextos do cotidiano.</span
                      >
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </template>
          </v-hover>
        </v-col>
      </v-row>
      <v-row dense class="mt-6" style="gap: 10vh">
        <v-col
          class="d-flex align-center justify-center slide-left-to-right"
          style="background-color: transparent !important"
          cols="12"
          sm="12"
          md="6"
        >
          <v-hover>
            <template v-slot="{ hover }">
              <v-card
                :elevation="hover ? 6 : 0"
                style="background-color: transparent !important"
              >
                <v-card-text style="min-height: 150px">
                  <v-row dense>
                    <v-col class="d-flex align-center justify-center">
                      <v-img
                        src="@/assets/icons/balloon_pink.svg"
                        max-width="40px"
                        contain
                      ></v-img>
                    </v-col>
                    <v-col cols="12" md="10" sm="10">
                      <h6 class="clipedText primary text-start button-large">
                        Feedback construtivo e continuo
                      </h6>
                    </v-col>
                  </v-row>
                  <v-row dense>
                    <v-col></v-col>
                    <v-col
                      cols="12"
                      md="10"
                      sm="10"
                      class="d-flex align-center justify-center"
                    >
                      <span
                        class="poppins body-text"
                        style="background-clip: text; font-size: 16px"
                      >
                        Nossos professores não apenas avaliam o desempenho, mas
                        também, identificam os pontos fortes e áreas de
                        melhoria.</span
                      >
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </template>
          </v-hover>
        </v-col>
        <v-col
          class="d-flex align-center justify-center slide-right-to-left"
          style="background-color: transparent !important"
        >
          <v-hover>
            <template v-slot="{ hover }">
              <v-card
                :elevation="hover ? 6 : 0"
                style="background-color: transparent !important"
              >
                <v-card-text style="min-height: 150px">
                  <v-row dense>
                    <v-col class="d-flex align-center justify-center">
                      <v-img
                        contain
                        src="@/assets/icons/joystick.svg"
                        max-width="40px"
                      ></v-img>
                    </v-col>
                    <v-col cols="12" md="10" sm="10">
                      <h6 class="clipedText primary text-start button-large">
                        Recursos didáticos interativos
                      </h6>
                    </v-col>
                  </v-row>
                  <v-row dense>
                    <v-col></v-col>
                    <v-col
                      cols="12"
                      md="10"
                      sm="10"
                      class="d-flex align-center justify-center"
                    >
                      <span
                        class="poppins body-text"
                        style="background-clip: text; font-size: 16px"
                      >
                        A utilização de materiais interativos enriquece e
                        potencializa o processo de aprendizado do inglês,
                        tornando-o mais envolvente e eficaz.</span
                      >
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </template>
          </v-hover>
        </v-col>
      </v-row>

      <v-row v-if="$vuetify.breakpoint.xs">
        <v-col>
          <v-card
            elevation="0"
            style="background-color: transparent !important"
          >
            <v-card-text
              style="
                background-color: transparent !important;
                border-radius: 10px;
                min-height: 30vh;
              "
            >
              <v-row dense>
                <v-col
                  cols="auto"
                  md="2"
                  sm="2"
                  xs="auto"
                  class="d-flex align-center justify-start"
                >
                  <v-img
                    @click="updateYear('decrement')"
                    max-width="15"
                    src="@/assets/icons/left_arrow.svg"
                  ></v-img>
                </v-col>
                <v-col cols="10" md="4" sm="auto" xs="auto">
                  <div class="d-flex align-center justify-center">
                    <h6 style="font-size: 24px" class="primary clipedText">
                      {{ currentYear }}
                    </h6>
                  </div>
                  <div class="d-flex align-center justify-center">
                    <span class="primary clipedText">
                      {{ displayableYears }}
                    </span>
                  </div>
                </v-col>
                <v-col
                  cols="auto"
                  md="4"
                  sm="auto"
                  xs="auto"
                  class="d-flex align-center justify-end"
                >
                  <v-img
                    @click="updateYear('increment')"
                    max-width="15"
                    src="@/assets/icons/right_arrow.svg"
                  ></v-img>
                </v-col>
              </v-row>
              <v-row dense>
                <v-col cols="12" md="12" sm="12">
                  <v-row dense class="card">
                    <v-col
                      cols="12"
                      md="12"
                      sm="12"
                      class="d-flex align-center justify-center"
                    >
                      <v-img
                        max-width="80"
                        :src="currentAnimationItem.image"
                      ></v-img>
                    </v-col>

                    <v-col
                      cols="12"
                      md="12"
                      sm="12"
                      class="d-flex justify-start ml-5 pa-0 mb-0"
                    >
                      <h6 style="color: #5f5f61" class="mt-4 animation-text">
                        {{ currentAnimationItem.title }}
                      </h6>
                    </v-col>
                    <div class="ml-5 mt-4">
                      <span class="inter">{{
                        currentAnimationItem.subtitle
                      }}</span>
                    </div>
                  </v-row>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </v-main>
</template>
<script>
export default {
  name: "DiferentialPage",
  components: {},
  mounted() {
    this.startCarousel();
  },
  computed: {
    displayableYears() {
      return this.years.filter((year) => year != this.currentYear).join("   ");
    },
    windowSize() {
      const size = window.innerHeight;

      if (this.$vuetify.breakpoint.mdAndUp) return "220px";
      if (size < 800) return "225px";
      else if (size > 800 && size < 1091) return "220px";
      else return "270px";
    },
  },
  created() {},
  methods: {
    openLink(link) {
      window.open(link, "_blank");
    },
    startCarousel() {
      this.setCarouselAnimation(this.currentYear);
      setTimeout(() => this.updateYear("increment"), 5000);
    },
    updateYear(type) {
      clearInterval(this.interval);
      if (type === "increment")
        this.currentYear < this.years[this.years.length - 1]
          ? this.currentYear++
          : (this.currentYear = this.years[0]);
      else
        this.currentYear > this.years[0] ? this.currentYear-- : this.years[0];
    },
    setCarouselAnimation(year) {
      const content = this.content[year];
      if (content.length > 1) {
        let item = 0;

        this.interval = setInterval(() => {
          if (!content[item]) {
            this.updateYear("increment");
            clearInterval(this.interval);
          }
          this.currentAnimationItem = content[item];
          item++;
        }, 5000);

        this.currentAnimationItem = content[0];
      } else {
        this.currentAnimationItem = content[0];
      }
    },
  },
  watch: {
    currentYear(year) {
      this.setCarouselAnimation(year);
    },
  },
  data() {
    return {
      interval: null,
      currentAnimationItem: {},
      currentYear: 2020,
      years: [2020, 2021, 2022, 2023, 2024],
      content: {
        2020: [
          {
            image: require("@/assets/images/carousel/2020_1.svg"),
            title: "Início do Curso",
            subtitle:
              "Primeira aula e começo da metodologia irlandesa Conversation Experience no Brasil.",
          },
        ],
        2021: [
          {
            image: require("@/assets/images/carousel/2021_1.svg"),
            title: "Adoção do nome",
            subtitle:
              "O curso assume oficialmente o nome de Pitch English Course.",
          },
          {
            image: require("@/assets/images/carousel/2021_2.svg"),
            title: "Profissionalização dos Materiais",
            subtitle: "Aprimoramento dos materiais das aulas.",
          },
          {
            image: require("@/assets/images/carousel/2021_3.svg"),
            title: "Aulas de Conversação Gratuitas",
            subtitle: "Início das aulas de conversação em grupo.",
          },
        ],
        2022: [
          {
            image: require("@/assets/images/carousel/2022_1.svg"),
            title: "Expansão e Verificação",
            subtitle:
              "O curso atinge a marca de 170 alunos e 12 professores, com a metodologia verificada.",
          },
          {
            image: require("@/assets/images/carousel/2022_2.svg"),
            title: "Material Pré-Aula",
            subtitle:
              "Criação de material pré-aula para fortalecer a didática da sala de aula invertida.",
          },
          {
            image: require("@/assets/images/carousel/2022_3.svg"),
            title: "Lançamento do site",
            subtitle: "O pitch English ganha um site próprio.",
          },
        ],
        2023: [
          {
            image: require("@/assets/images/carousel/2021_1.svg"),
            title: "plataforma de Exercícios",
            subtitle: "Criação da Plataforma Pitch English para exercícios.",
          },
          {
            image: require("@/assets/images/carousel/2021_2.svg"),
            title: "Pitch App",
            subtitle:
              "Lançamento do Pitch app, proporcionando estudo dinâmico para os alunos.",
          },
        ],
        2024: [
          {
            image: require("@/assets/images/carousel/2024_1.svg"),
            title: "+300 Alunos",
            subtitle: "Mais de 300 alunos estudaram no Pitch English Course.",
          },
          {
            image: require("@/assets/images/carousel/2024_2.svg"),
            title: "Expansão Nacional e Internacional",
            subtitle: "Presente em 9 estados brasileiros e em 4 países.",
          },
          {
            image: require("@/assets/images/carousel/2024_3.svg"),
            title: "Gamificação dos Materiais",
            subtitle:
              "Introduão de elementos de gamificação nos materiais de estudo.",
          },
        ],
      },
    };
  },
};
</script>

<style scoped>
.clipedText {
  background-clip: text;
  color: transparent;
}

.arrows {
  aspect-ratio: 20/8;
}

.card {
  border: 1px solid #cdcdd1;
  border-radius: 10px;
}

.progress-bar-container {
  width: 100%;
  border-radius: 10px;
  overflow: hidden;
}

.progress-bar {
  background-color: linear-gradient(90deg, #9923cd 0%, #e8276f 75.67%);
  height: 10px;
  border-radius: 10px 0 0 10px;
  transition: width 0.5s ease-in-out;
}
</style>
