<template>
  <div>
    <v-app-bar
      app
      height="100vh"
      class="mt-0 white"
      v-if="isMediumOrHigher"
      dense
    >
      <v-row dense class="overflow-x-auto overflow-y-auto">
        <v-col class="d-flex align-center justify-center">
          <v-img
            src="@/assets/images/logo_pitch.webp"
            contain
            max-width="3vw"
          />
        </v-col>
        <v-col class="d-flex align-center justify-center">
          <v-hover>
            <template v-slot="{ hover }">
              <v-btn plain large @click="scrollToSection('metodologia')">
                <span
                  class="text-capitalize poppins"
                  :style="{ color: hover ? '#e8276f' : '' }"
                  style="
                    font-weight: bolder;
                    font-size: 17px;
                    font-family: 'Poppins', 'sans-serif';
                  "
                  >Metodologia</span
                >
              </v-btn>
            </template>
          </v-hover>
        </v-col>
        <v-col class="d-flex align-center justify-center">
          <v-hover>
            <template v-slot="{ hover }">
              <v-btn plain large @click="scrollToSection('diferencial')">
                <span
                  class="text-capitalize poppins"
                  :style="{ color: hover ? '#e8276f' : '' }"
                  style="
                    font-weight: bolder;
                    font-size: 17px;
                    font-family: 'Poppins', 'sans-serif';
                  "
                  >Diferencial</span
                >
              </v-btn>
            </template>
          </v-hover>
        </v-col>
        <v-col class="d-flex align-center justify-center">
          <v-hover>
            <template v-slot="{ hover }">
              <v-btn plain large @click="scrollToSection('planos')">
                <span
                  class="text-capitalize poppins"
                  :style="{ color: hover ? '#e8276f' : '' }"
                  style="
                    font-weight: bolder;
                    font-size: 17px;
                    font-family: 'Poppins', 'sans-serif';
                  "
                  >Planos</span
                >
              </v-btn>
            </template>
          </v-hover>
        </v-col>
        <v-col class="d-flex align-center justify-center">
          <v-hover>
            <template v-slot="{ hover }">
              <v-btn plain large @click="scrollToSection('faq')">
                <span
                  class="text-capitalize poppins"
                  :style="{ color: hover ? '#e8276f' : '' }"
                  style="
                    font-weight: bolder;
                    font-size: 17px;
                    font-family: 'Poppins', 'sans-serif';
                  "
                  >FAQ</span
                >
              </v-btn>
            </template>
          </v-hover>
        </v-col>
        <v-col class="d-flex align-center justify-center">
          <v-hover>
            <template v-slot="{ hover }">
              <v-btn plain large @click="openLink(whatsAppLink)">
                <v-img class="mr-1" src="@/assets/icons/balloon.svg"></v-img>
                <span
                  class="text-capitalize poppins"
                  :style="{ color: hover ? '#e8276f' : '' }"
                  style="
                    font-weight: bolder;
                    font-size: 17px;
                    font-family: 'Poppins', 'sans-serif';
                  "
                  >Fale conosco</span
                >
              </v-btn>
            </template>
          </v-hover>
        </v-col>
        <v-col class="d-flex align-center justify-center">
          <v-hover>
            <template v-slot="{ hover }">
              <v-btn plain large @click="scrollToSection('seja-licenciado')">
                <v-img class="mr-1" src="@/assets/icons/company.svg"></v-img>
                <span
                  class="text-capitalize poppins"
                  :style="{ color: hover ? '#e8276f' : '' }"
                  style="
                    font-weight: bolder;
                    font-size: 17px;
                    font-family: 'Poppins', 'sans-serif';
                  "
                  >Seja licenciado</span
                >
              </v-btn>
            </template>
          </v-hover>
        </v-col>
        <v-col class="d-flex align-center justify-center">
          <v-hover>
            <template v-slot="{ hover }">
              <v-btn
                class="menuButton login text-capitalize"
                @mouseover="loginText = 'Em breve'"
                @mouseleave="loginText = 'Pitch Connect'"
                depresed
                rounded
                color="primary"
                style="
                  width: 200px;
                  font-weight: bolder;
                  font-size: 16px;
                  font-family: 'Poppins', 'sans-serif';
                "
              >
                <v-icon v-if="!hover">{{ pitchConnectIcons.default }}</v-icon>
                <v-icon v-else>{{ pitchConnectIcons.hovered }}</v-icon>
                <span style="width: 100%; font-weight: bolder !important">
                  {{ loginText }}
                </span>
              </v-btn>
            </template>
          </v-hover>
        </v-col>
      </v-row>
    </v-app-bar>
    <v-app-bar fixed v-else>
      <v-app-bar-nav-icon @click="openDrawer()" fixed> </v-app-bar-nav-icon>
    </v-app-bar>
    <v-container>
      <v-navigation-drawer v-model="drawer" absolute temporary>
        <v-list nav dense>
          <v-list-item v-for="(menuItem, index) in menuItems" :key="index">
            <v-list-item-title @click="scrollToSection(menuItem.section)">{{
              menuItem.text
            }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-navigation-drawer>
    </v-container>
  </div>
</template>
<script>
export default {
  name: "TopMenu",
  props: {},
  data() {
    return {
      drawer: false,
      group: null,

      pitchConnectIcons: {
        default: "mdi-lock-outline",
        hovered: "mdi-lock-open-outline",
      },
      whatsAppLink: `https://api.whatsapp.com/send?phone=${this.whatsApp()}&text=Ol%C3%A1+%21+Vim+pelo+site+do+curso+e+gostaria+de+saber+mais+informa%C3%A7%C3%B5es%21+`,
      pitchWhatsApp:
        "https://api.whatsapp.com/send?phone=5532991934626&text=Ol%C3%A1%21+Vim+pelo+site+do+curso+e+gostaria+de+agendar+uma+aula+gratuita%21",
      menuItems: [
        { text: "Metodologia", section: "metodologia" },
        {
          text: "Diferencial",
          section: "diferencial",
        },
        { text: "Planos", section: "planos" },
        { text: "FAQ", section: "faq" },
        {
          text: "Fale conosco",
          link: ``,
          customIcon: true,
          image: require("@/assets/icons/balloon.svg"),
        },
        {
          text: "Seja licenciado",
          customIcon: true,
          image: require("@/assets/icons/company.svg"),
          section: "seja-licenciado",
        },
      ],
      loginText: "Pitch Connect",
    };
  },
  computed: {
    isMediumOrHigher() {
      return window.innerWidth >= 960;
    },
  },
  watch: {},
  mounted() {},
  methods: {
    openLink(link) {
      window.open(link, "_blank");
    },
    scrollToSection(sectionId) {
      if (sectionId !== "seja-licenciado") this.$emit("scroll", sectionId);
      else
        this.$router.push({
          path: "/seja-licenciado",
        });
    },
    openDrawer() {
      scrollTo(0, 0);
      this.drawer = true;
    },
    whatsApp() {
      return process.env.VUE_APP_PITCH_WPP.replaceAll(" ", "").replaceAll(
        "-",
        ""
      );
    },
  },
};
</script>
<style scoped></style>
