<template>
  <v-container>
    <v-row class="mt-6">
      <v-col cols="12" md="5" sm="5" class="d-flex align-center justify-start">
      </v-col>
      <v-col cols="12" md="7" sm="7" class="d-flex align-center justify-start">
        <span class="text-h5 bolder" style="color: #5f5f61"
          >Políticas de privacidade</span
        >
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="5" sm="5" class="d-flex align-start justify-start">
        <v-card elevation="0" style="background-color: transparent">
          <v-card-text>
            <v-row
              dense
              v-for="(item, index) in content"
              :key="index"
              class="mt-2"
            >
              <v-col cols="auto" class="d-flex align-center jutisy-center">
                <v-img
                  src="@/assets/icons/arrow_outline.svg"
                  contain
                  max-height="15px"
                />
              </v-col>
              <v-col>
                <span style="color: #5f5f61" class="bolder">{{
                  item.title
                }}</span>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" md="7" sm="7" class="d-flex align-center justify-start">
        <v-card elevation="0" style="background-color: transparent">
          <v-card-text>
            <v-row v-for="(item, index) in content" :key="index">
              <v-col cols="12" md="11" sm="11">
                <span style="color: #5f5f61" class="bolder"
                  >{{ index + 1 }}.</span
                ><span style="color: #5f5f61" class="bolder text-h7 ml-1"
                  >{{ item.title }}:</span
                ><br />
                <span>{{ item.text }}</span>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
export default {
  name: "PrivacySreen",
  components: {},
  mixins: [],
  props: {},
  data() {
    return {
      greetings:
        "Bem-vindo à Política de Privacidade do Pitch English. Nossa prioridade é proteger e respeitar a sua privacidade. Esta política detalha como coletamos, usamos e protegemos suas informações pessoais. Ao utilizar nossos serviços, você concorda com os termos desta política.",
      content: [
        {
          title: "Informações Coletadas",
          text: "Coletamos informações que você nos fornece voluntariamente, como nome, e-mail e outras informações de contato, além de registrar dados sobre como você interage com nosso site, incluindo páginas visitadas, tempos de visita e outras estatísticas.",
        },
        {
          title: "Uso das Informações",
          text: "Utilizamos suas informações para oferecer nossos serviços, processar transações e fornecer suporte ao cliente. Além disso, analisamos dados de uso para melhorar continuamente a qualidade e a experiência do usuário em nosso site.",
        },
        {
          title: "Proteção das Informações",
          text: "Implementamos medidas de segurança para proteger suas informações contra acesso não autorizado, alteração, divulgação ou destruição. O acesso às suas informações é restrito a funcionários autorizados que precisam delas para desempenhar suas funções.",
        },
        {
          title: "Compartilhamento de Informações",
          text: "Podemos compartilhar suas informações com terceiros autorizados para fornecer serviços específicos, como processamento de pagamentos, sempre respeitando o seu consentimento e cumprindo obrigações legais.",
        },
        {
          title: "Cookies e Tecnologias Semelhantes",
          text: "Utilizamos cookies e tecnologias similares para personalizar sua experiência no site, analisar padrões de tráfego e melhorar a funcionalidade.",
        },
        {
          title: "Links para Sites de Terceiros",
          text: "Nosso site pode conter links para sites de terceiros. Não somos responsáveis pelas práticas de privacidade desses sites.",
        },
        {
          title: "Alterações na Política de Privacidade",
          text: "Reservamo-nos o direito de modificar esta política a qualquer momento. Alterações serão comunicadas por meio do site.",
        },
      ],
    };
  },
  computed: {},
  watch: {},
  mounted() {},
  methods: {},
};
</script>
<style scoped>
.bolder {
  font-weight: bold;
}
</style>
