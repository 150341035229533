<template>
  <v-container style="margin-top: 8vh">
    <v-row dense>
      <v-col cols="12" md="6"></v-col>
      <v-col
        cols="12"
        md="6"
        class="d-flex align-start justify-center flex-column"
      >
        <gradient-bar style="max-width: 15vh !important" />

        <h6 class="primary clipedText mt-4" style="font-size: 19.2">FAQ</h6>
      </v-col>
    </v-row>

    <v-row dense style="margin-top: 5vh"
      ><v-col cols="12" md="6"></v-col>
      <v-col cols="12" md="6"
        ><h3 class="clipedText primary">Dúvidas frequentes</h3></v-col
      ></v-row
    >

    <v-row no-gutters>
      <v-col
        cols="12"
        md="6"
        class="d-flex align-center justify-center pr-3"
        style="background-color: transparent !important"
      >
        <v-img contain height="50vh" src="@/assets/images/faq/faq.png"></v-img>
      </v-col>
      <v-col
        cols="12"
        md="6"
        class="d-flex align-center justify-center slide-right-to-left"
        style="background-color: transparent !important"
      >
        <v-expansion-panels>
          <v-expansion-panel
            v-for="(panel, index) in items"
            :key="index"
            class="mt-2"
            style="border-radius: 10px"
          >
            <v-expansion-panel-header disable-icon-rotate>
              <template v-slot:actions>
                <v-icon> mdi-plus </v-icon>
              </template>
              <span class="text-h6" style="color: #5f5f61; font-weight: bold">{{
                panel.title
              }}</span>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <span class="inter">{{ panel.text }}</span>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
export default {
  name: "FaqSection",
  components: {},
  mixins: [],
  props: {},
  data() {
    return {
      items: [
        {
          title: "Posso trocar de plano no momento que eu quiser?",
          text: "Claro que pode! O Pitch é super flexível e não cobra nada para você trocar de plano. Você escolhe o que é melhor para você.",
        },
        {
          title: "Pago alguma taxa ao mudar de plano?",
          text: "Não! Você tem total liberdade para cancelar o seu plano a qualquer momento. Só pedimos que você nos avise com pelo menos 30 dias de antecedência, ok?",
        },
        {
          title: "Quais são os horários das turmas?",
          text: "Você faz o eu próprio horário! Você pode ter aulas de segunda a sábado, no horário que for mais conveninente para você. É só combinar com o seu professor!",
        },
        {
          title: "Preciso pagar pelo material?",
          text: "Não precisa! O curso oferece todo o material didático gratuitamente você só precisa se preocupar em aprender!",
        },
        {
          title: "Posso remarcar uma aula?",
          text: "Pode sim! se você tiver algum imprevisto, é só avisar o seu professor com até 24 horas de antecedência e remarcar a sua aula.",
        },
        {
          title: "Posso acessar as aulas com o celular?",
          text: "Você pode usar o dispositivo que preferir, seja computador, celular ou tablet. O imortante é ter uma boa conexão com a internet!",
        },
        {
          title: "Como são realizadas as aulas?",
          text: "As aulas são ao vivo e online, realizadas pelo Google Meet ou pela plataforma que você e seu professor combinarem. Você vai interagir com o seu professor em tempo real, como se estivessem em uma sala de aula presencial!",
        },
        {
          title: "De que forma é feito o pagamento?",
          text: "Aqui no Pitch você paga depois que aprende! Voce só paga no mês seguinte ao que as aulas foram realizadas, por meio de um boleto que você poderá escolher o melhor dia de pagamento.",
        },
      ],
    };
  },
  computed: {},
  watch: {},
  mounted() {},
  methods: {},
};
</script>
<style scoped>
.headerText {
  font-size: 45px;
  white-space: normal;
  font-style: bold;
  overflow: visible;
  padding: 5px;
}

.clipedText {
  background-clip: text;
  color: transparent;
}

.menuLinear {
  width: 97px;
  background-color: linear-gradient(to right, #9923cd, #e8276f);
}

.bolder {
  font-weight: bolder;
}

.secondaryText {
  color: "#5FF5F61" !important;
  font-size: 16px !important;
  font-weight: 400 !important;
  white-space: normal !important;
}
</style>
