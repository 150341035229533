import Vue from "vue";
import VueRouter from "vue-router";
import { public_routes } from "./public-routes";
// import { customer_routes } from "./customer-routes";
// import { partner_routes } from "./partner-routes";
// import beforeEach from "./beforeEach";

Vue.use(VueRouter);

const routes = [...public_routes];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

// router.beforeEach(beforeEach);

export default router;
