export const countries = [
  { ddi: "93", name: "Afghanistan", flag: "🇦🇫" },
  { ddi: "355", name: "Albania", flag: "🇦🇱" },
  { ddi: "213", name: "Algeria", flag: "🇩🇿" },
  { ddi: "1684", name: "American Samoa", flag: "🇦🇸" },
  { ddi: "376", name: "Andorra", flag: "🇦🇩" },
  { ddi: "244", name: "Angola", flag: "🇦🇴" },
  { ddi: "1264", name: "Anguilla", flag: "🇦🇮" },
  { ddi: "672", name: "Antarctica", flag: "🇦🇶" },
  { ddi: "1268", name: "Antigua and Barbuda", flag: "🇦🇬" },
  { ddi: "54", name: "Argentina", flag: "🇦🇷" },
  { ddi: "374", name: "Armenia", flag: "🇦🇲" },
  { ddi: "297", name: "Aruba", flag: "🇦🇼" },
  { ddi: "61", name: "Australia", flag: "🇦🇺" },
  { ddi: "43", name: "Austria", flag: "🇦🇹" },
  { ddi: "994", name: "Azerbaijan", flag: "🇦🇿" },
  { ddi: "1242", name: "Bahamas", flag: "🇧🇸" },
  { ddi: "973", name: "Bahrain", flag: "🇧🇭" },
  { ddi: "880", name: "Bangladesh", flag: "🇧🇩" },
  { ddi: "1246", name: "Barbados", flag: "🇧🇧" },
  { ddi: "375", name: "Belarus", flag: "🇧🇾" },
  { ddi: "32", name: "Belgium", flag: "🇧🇪" },
  { ddi: "501", name: "Belize", flag: "🇧🇿" },
  { ddi: "229", name: "Benin", flag: "🇧🇯" },
  { ddi: "1441", name: "Bermuda", flag: "🇧🇲" },
  { ddi: "975", name: "Bhutan", flag: "🇧🇹" },
  { ddi: "591", name: "Bolivia", flag: "🇧🇴" },
  { ddi: "387", name: "Bosnia and Herzegovina", flag: "🇧🇦" },
  { ddi: "267", name: "Botswana", flag: "🇧🇼" },
  { ddi: "55", name: "Brasil", flag: "🇧🇷" },
  { ddi: "246", name: "British Indian Ocean Territory", flag: "🇮🇴" },
  { ddi: "1284", name: "British Virgin Islands", flag: "🇻🇬" },
  { ddi: "673", name: "Brunei", flag: "🇧🇳" },
  { ddi: "359", name: "Bulgaria", flag: "🇧🇬" },
  { ddi: "226", name: "Burkina Faso", flag: "🇧🇫" },
  { ddi: "257", name: "Burundi", flag: "🇧🇮" },
  { ddi: "855", name: "Cambodia", flag: "🇰🇭" },
  { ddi: "237", name: "Cameroon", flag: "🇨🇲" },
  { ddi: "1", name: "Canada", flag: "🇨🇦" },
  { ddi: "238", name: "Cape Verde", flag: "🇨🇻" },
  { ddi: "1345", name: "Cayman Islands", flag: "🇰🇾" },
  { ddi: "236", name: "Central African Republic", flag: "🇨🇫" },
  { ddi: "235", name: "Chad", flag: "🇹🇩" },
  { ddi: "56", name: "Chile", flag: "🇨🇱" },
  { ddi: "86", name: "China", flag: "🇨🇳" },
  { ddi: "61", name: "Christmas Island", flag: "🇨🇽" },
  { ddi: "61", name: "Cocos Islands", flag: "🇨🇨" },
  { ddi: "57", name: "Colombia", flag: "🇨🇴" },
  { ddi: "269", name: "Comoros", flag: "🇰🇲" },
  { ddi: "682", name: "Cook Islands", flag: "🇨🇰" },
  { ddi: "506", name: "Costa Rica", flag: "🇨🇷" },
  { ddi: "385", name: "Croatia", flag: "🇭🇷" },
  { ddi: "53", name: "Cuba", flag: "🇨🇺" },
  { ddi: "599", name: "Curacao", flag: "🇨🇼" },
  { ddi: "357", name: "Cyprus", flag: "🇨🇾" },
  { ddi: "420", name: "Czech Republic", flag: "🇨🇿" },
  { ddi: "243", name: "Democratic Republic of the Congo", flag: "🇨🇩" },
  { ddi: "45", name: "Denmark", flag: "🇩🇰" },
  { ddi: "253", name: "Djibouti", flag: "🇩🇯" },
  { ddi: "1767", name: "Dominica", flag: "🇩🇲" },
  { ddi: "1809", name: "Dominican Republic", flag: "🇩🇴" },
  { ddi: "670", name: "East Timor", flag: "🇹🇱" },
  { ddi: "593", name: "Ecuador", flag: "🇪🇨" },
  { ddi: "20", name: "Egypt", flag: "🇪🇬" },
  { ddi: "503", name: "El Salvador", flag: "🇸🇻" },
  { ddi: "240", name: "Equatorial Guinea", flag: "🇬🇶" },
  { ddi: "291", name: "Eritrea", flag: "🇪🇷" },
  { ddi: "372", name: "Estonia", flag: "🇪🇪" },
  { ddi: "251", name: "Ethiopia", flag: "🇪🇹" },
  { ddi: "500", name: "Falkland Islands", flag: "🇫🇰" },
  { ddi: "298", name: "Faroe Islands", flag: "🇫🇴" },
  { ddi: "679", name: "Fiji", flag: "🇫🇯" },
  { ddi: "358", name: "Finland", flag: "🇫🇮" },
  { ddi: "33", name: "France", flag: "🇫🇷" },
  { ddi: "594", name: "French Guiana", flag: "🇬🇫" },
  { ddi: "689", name: "French Polynesia", flag: "🇵🇫" },
  { ddi: "241", name: "Gabon", flag: "🇬🇦" },
  { ddi: "220", name: "Gambia", flag: "🇬🇲" },
  { ddi: "995", name: "Georgia", flag: "🇬🇪" },
  { ddi: "49", name: "Germany", flag: "🇩🇪" },
  { ddi: "233", name: "Ghana", flag: "🇬🇭" },
  { ddi: "350", name: "Gibraltar", flag: "🇬🇮" },
  { ddi: "30", name: "Greece", flag: "🇬🇷" },
  { ddi: "299", name: "Greenland", flag: "🇬🇱" },
  { ddi: "1473", name: "Grenada", flag: "🇬🇩" },
  { ddi: "590", name: "Guadeloupe", flag: "🇬🇵" },
  { ddi: "1671", name: "Guam", flag: "🇬🇺" },
  { ddi: "502", name: "Guatemala", flag: "🇬🇹" },
  { ddi: "441481", name: "Guernsey", flag: "🇬🇬" },
  { ddi: "224", name: "Guinea", flag: "🇬🇳" },
  { ddi: "245", name: "GuineaBissau", flag: "🇬🇼" },
  { ddi: "592", name: "Guyana", flag: "🇬🇾" },
  { ddi: "509", name: "Haiti", flag: "🇭🇹" },
  { ddi: "504", name: "Honduras", flag: "🇭🇳" },
  { ddi: "852", name: "Hong Kong", flag: "🇭🇰" },
  { ddi: "36", name: "Hungary", flag: "🇭🇺" },
  { ddi: "354", name: "Iceland", flag: "🇮🇸" },
  { ddi: "91", name: "India", flag: "🇮🇳" },
  { ddi: "62", name: "Indonesia", flag: "🇮🇩" },
  { ddi: "98", name: "Iran", flag: "🇮🇷" },
  { ddi: "964", name: "Iraq", flag: "🇮🇶" },
  { ddi: "353", name: "Ireland", flag: "🇮🇪" },
  { ddi: "441624", name: "Isle of Man", flag: "🇮🇲" },
  { ddi: "972", name: "Israel", flag: "🇮🇱" },
  { ddi: "39", name: "Italy", flag: "🇮🇹" },
  { ddi: "225", name: "Ivory Coast", flag: "🇨🇮" },
  { ddi: "1876", name: "Jamaica", flag: "🇯🇲" },
  { ddi: "81", name: "Japan", flag: "🇯🇵" },
  { ddi: "441534", name: "Jersey", flag: "🇯🇪" },
  { ddi: "962", name: "Jordan", flag: "🇯🇴" },
  { ddi: "7", name: "Kazakhstan", flag: "🇰🇿" },
  { ddi: "254", name: "Kenya", flag: "🇰🇪" },
  { ddi: "686", name: "Kiribati", flag: "🇰🇮" },
  { ddi: "383", name: "Kosovo", flag: "🇽🇰" },
  { ddi: "965", name: "Kuwait", flag: "🇰🇼" },
  { ddi: "996", name: "Kyrgyzstan", flag: "🇰🇬" },
  { ddi: "856", name: "Laos", flag: "🇱🇦" },
  { ddi: "371", name: "Latvia", flag: "🇱🇻" },
  { ddi: "961", name: "Lebanon", flag: "🇱🇧" },
  { ddi: "266", name: "Lesotho", flag: "🇱🇸" },
  { ddi: "231", name: "Liberia", flag: "🇱🇷" },
  { ddi: "218", name: "Libya", flag: "🇱🇾" },
  { ddi: "423", name: "Liechtenstein", flag: "🇱🇮" },
  { ddi: "370", name: "Lithuania", flag: "🇱🇹" },
  { ddi: "352", name: "Luxembourg", flag: "🇱🇺" },
  { ddi: "853", name: "Macau", flag: "🇲🇴" },
  { ddi: "389", name: "Macedonia", flag: "🇲🇰" },
  { ddi: "261", name: "Madagascar", flag: "🇲🇬" },
  { ddi: "265", name: "Malawi", flag: "🇲🇼" },
  { ddi: "60", name: "Malaysia", flag: "🇲🇾" },
  { ddi: "960", name: "Maldives", flag: "🇲🇻" },
  { ddi: "223", name: "Mali", flag: "🇲🇱" },
  { ddi: "356", name: "Malta", flag: "🇲🇹" },
  { ddi: "692", name: "Marshall Islands", flag: "🇲🇭" },
  { ddi: "596", name: "Martinique", flag: "🇲🇶" },
  { ddi: "222", name: "Mauritania", flag: "🇲🇷" },
  { ddi: "230", name: "Mauritius", flag: "🇲🇺" },
  { ddi: "262", name: "Mayotte", flag: "🇾🇹" },
  { ddi: "52", name: "Mexico", flag: "🇲🇽" },
  { ddi: "691", name: "Micronesia", flag: "🇫🇲" },
  { ddi: "373", name: "Moldova", flag: "🇲🇩" },
  { ddi: "377", name: "Monaco", flag: "🇲🇨" },
  { ddi: "976", name: "Mongolia", flag: "🇲🇳" },
  { ddi: "382", name: "Montenegro", flag: "🇲🇪" },
  { ddi: "1664", name: "Montserrat", flag: "🇲🇸" },
  { ddi: "212", name: "Morocco", flag: "🇲🇦" },
  { ddi: "258", name: "Mozambique", flag: "🇲🇿" },
  { ddi: "95", name: "Myanmar", flag: "🇲🇲" },
  { ddi: "264", name: "Namibia", flag: "🇳🇦" },
  { ddi: "674", name: "Nauru", flag: "🇳🇷" },
  { ddi: "977", name: "Nepal", flag: "🇳🇵" },
  { ddi: "31", name: "Netherlands", flag: "🇳🇱" },
  { ddi: "687", name: "New Caledonia", flag: "🇳🇨" },
  { ddi: "64", name: "New Zealand", flag: "🇳🇿" },
  { ddi: "505", name: "Nicaragua", flag: "🇳🇮" },
  { ddi: "227", name: "Niger", flag: "🇳🇪" },
  { ddi: "234", name: "Nigeria", flag: "🇳🇬" },
  { ddi: "683", name: "Niue", flag: "🇳🇺" },
  { ddi: "850", name: "North Korea", flag: "🇰🇵" },
  { ddi: "1670", name: "Northern Mariana Islands", flag: "🇲🇵" },
  { ddi: "47", name: "Norway", flag: "🇳🇴" },
  { ddi: "968", name: "Oman", flag: "🇴🇲" },
  { ddi: "92", name: "Pakistan", flag: "🇵🇰" },
  { ddi: "680", name: "Palau", flag: "🇵🇼" },
  { ddi: "970", name: "Palestine", flag: "🇵🇸" },
  { ddi: "507", name: "Panama", flag: "🇵🇦" },
  { ddi: "675", name: "Papua New Guinea", flag: "🇵🇬" },
  { ddi: "595", name: "Paraguay", flag: "🇵🇾" },
  { ddi: "51", name: "Peru", flag: "🇵🇪" },
  { ddi: "63", name: "Philippines", flag: "🇵🇭" },
  { ddi: "48", name: "Poland", flag: "🇵🇱" },
  { ddi: "351", name: "Portugal", flag: "🇵🇹" },
  { ddi: "1787", name: "Puerto Rico", flag: "🇵🇷" },
  { ddi: "974", name: "Qatar", flag: "🇶🇦" },
  { ddi: "242", name: "Republic of the Congo", flag: "🇨🇬" },
  { ddi: "262", name: "Reunion", flag: "🇷🇪" },
  { ddi: "40", name: "Romania", flag: "🇷🇴" },
  { ddi: "7", name: "Russia", flag: "🇷🇺" },
  { ddi: "250", name: "Rwanda", flag: "🇷🇼" },
  { ddi: "590", name: "Saint Barthelemy", flag: "🇧🇱" },
  { ddi: "290", name: "Saint Helena", flag: "🇸🇭" },
  { ddi: "1869", name: "Saint Kitts and Nevis", flag: "🇰🇳" },
  { ddi: "1758", name: "Saint Lucia", flag: "🇱🇨" },
  { ddi: "590", name: "Saint Martin", flag: "🇲🇫" },
  { ddi: "508", name: "Saint Pierre and Miquelon", flag: "🇵🇲" },
  { ddi: "1784", name: "Saint Vincent and the Grenadines", flag: "🇻🇨" },
  { ddi: "685", name: "Samoa", flag: "🇼🇸" },
  { ddi: "378", name: "San Marino", flag: "🇸🇲" },
  { ddi: "239", name: "Sao Tome and Principe", flag: "🇸🇹" },
  { ddi: "966", name: "Saudi Arabia", flag: "🇸🇦" },
  { ddi: "221", name: "Senegal", flag: "🇸🇳" },
  { ddi: "381", name: "Serbia", flag: "🇷🇸" },
  { ddi: "248", name: "Seychelles", flag: "🇸🇨" },
  { ddi: "232", name: "Sierra Leone", flag: "🇸🇱" },
  { ddi: "65", name: "Singapore", flag: "🇸🇬" },
  { ddi: "421", name: "Slovakia", flag: "🇸🇰" },
  { ddi: "386", name: "Slovenia", flag: "🇸🇮" },
  { ddi: "677", name: "Solomon Islands", flag: "🇸🇧" },
  { ddi: "252", name: "Somalia", flag: "🇸🇴" },
  { ddi: "27", name: "South Africa", flag: "🇿🇦" },
  { ddi: "82", name: "South Korea", flag: "🇰🇷" },
  { ddi: "211", name: "South Sudan", flag: "🇸🇸" },
  { ddi: "34", name: "Spain", flag: "🇪🇸" },
  { ddi: "94", name: "Sri Lanka", flag: "🇱🇰" },
  { ddi: "249", name: "Sudan", flag: "🇸🇩" },
  { ddi: "597", name: "Suriname", flag: "🇸🇷" },
  { ddi: "47", name: "Svalbard and Jan Mayen", flag: "🇸🇯" },
  { ddi: "268", name: "Swaziland", flag: "🇸🇿" },
  { ddi: "46", name: "Sweden", flag: "🇸🇪" },
  { ddi: "41", name: "Switzerland", flag: "🇨🇭" },
  { ddi: "963", name: "Syria", flag: "🇸🇾" },
  { ddi: "886", name: "Taiwan", flag: "🇹🇼" },
  { ddi: "992", name: "Tajikistan", flag: "🇹🇯" },
  { ddi: "255", name: "Tanzania", flag: "🇹🇿" },
  { ddi: "66", name: "Thailand", flag: "🇹🇭" },
  { ddi: "228", name: "Togo", flag: "🇹🇬" },
  { ddi: "690", name: "Tokelau", flag: "🇹🇰" },
  { ddi: "676", name: "Tonga", flag: "🇹🇴" },
  { ddi: "1868", name: "Trinidad and Tobago", flag: "🇹🇹" },
  { ddi: "216", name: "Tunisia", flag: "🇹🇳" },
  { ddi: "90", name: "Turkey", flag: "🇹🇷" },
  { ddi: "993", name: "Turkmenistan", flag: "🇹🇲" },
  { ddi: "1649", name: "Turks and Caicos Islands", flag: "🇹🇨" },
  { ddi: "688", name: "Tuvalu", flag: "🇹🇻" },
  { ddi: "256", name: "Uganda", flag: "🇺🇬" },
  { ddi: "380", name: "Ukraine", flag: "🇺🇦" },
  { ddi: "971", name: "United Arab Emirates", flag: "🇦🇪" },
  { ddi: "44", name: "United Kingdom", flag: "🇬🇧" },
  { ddi: "1", name: "United States", flag: "🇺🇸" },
  { ddi: "598", name: "Uruguay", flag: "🇺🇾" },
  { ddi: "998", name: "Uzbekistan", flag: "🇺🇿" },
  { ddi: "678", name: "Vanuatu", flag: "🇻🇺" },
  { ddi: "379", name: "Vatican", flag: "🇻🇦" },
  { ddi: "58", name: "Venezuela", flag: "🇻🇪" },
  { ddi: "84", name: "Vietnam", flag: "🇻🇳" },
  { ddi: "681", name: "Wallis and Futuna", flag: "🇼🇫" },
  { ddi: "212", name: "Western Sahara", flag: "🇪🇭" },
  { ddi: "967", name: "Yemen", flag: "🇾🇪" },
  { ddi: "260", name: "Zambia", flag: "🇿🇲" },
  { ddi: "263", name: "Zimbabwe", flag: "🇿🇼" },
];
