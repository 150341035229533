<template>
  <div>
    <TopMenu @scroll="scroll" />
    <v-container
      :style="{ 'margin-top': $vuetify.breakpoint.xs ? '50px' : '200px' }"
    >
      <v-row>
        <v-col
          cols="12"
          md="12"
          sm="12"
          xs="12"
          class="d-flex align-center justify-center"
        >
          <span class="text-h3 gradient bolder">
            Nossos Termos e Políticas
          </span>
        </v-col>
      </v-row>
      <v-row>
        <v-col
          cols="12"
          md="12"
          sm="12"
          class="d-flex align-center justify-center"
          v-if="$vuetify.breakpoint.mdAndUp"
        >
          <v-btn-toggle v-model="toggle_exclusive" rounded>
            <v-btn
              rounded
              class="bolder"
              :color="toggle_exclusive === 0 ? color : neutral"
              :class="{ selected: toggle_exclusive === 0 }"
            >
              Termos de Uso</v-btn
            >
            <v-btn
              rounded
              class="bolder"
              :color="toggle_exclusive === 1 ? color : neutral"
              :class="{ selected: toggle_exclusive === 1 }"
            >
              Políticas de privacidade</v-btn
            >
          </v-btn-toggle>
        </v-col>
        <v-row dense v-else>
          <v-col class="d-flex align-center justify-center">
            <v-btn
              rounded
              @click="toggle_exclusive = 0"
              class="bolder"
              :color="toggle_exclusive === 0 ? color : neutral"
              :class="{ selected: toggle_exclusive === 0 }"
            >
              Termos de Uso</v-btn
            ></v-col
          >

          <v-col class="d-flex align-center justify-center">
            <v-btn
              rounded
              @click="toggle_exclusive = 1"
              class="bolder"
              :color="toggle_exclusive === 1 ? color : neutral"
              :class="{ selected: toggle_exclusive === 1 }"
            >
              Políticas de privacidade</v-btn
            >
          </v-col>
        </v-row>
      </v-row>
      <v-row>
        <EULA v-if="toggle_exclusive === 0" />
        <PrivacyPolicy v-else />
      </v-row>
    </v-container>
    <Footer @scrollTo="scroll" />
  </div>
</template>
<script>
import PrivacyPolicy from "@/components/homepage/PrivacyPolicy.vue";
import TopMenu from "@/components/homepage/TopMenu.vue";
import Footer from "@/components/homepage/Footer.vue";
import EULA from "@/components/homepage/EULA.vue";

export default {
  name: "PoliciesScreen",
  components: { TopMenu, Footer, EULA, PrivacyPolicy },
  mixins: [],
  props: {},
  data() {
    return {
      toggle_exclusive: 1,
      color: "#FF2B7A",
      neutral: "#E1E1E6",
    };
  },
  computed: {},
  watch: {},
  mounted() {
    scrollTo(0, 0);
  },
  methods: {
    scroll() {
      this.$router.push({ path: "/" });
    },
  },
};
</script>
<style scoped>
.bolder {
  font-weight: bold;
}

.selected {
  color: white !important;
}

.radius {
  border-radius: 20px;
}

.gradient {
  font-size: 36px;
  background: linear-gradient(to right, #e8276f, #9923cd);
  background-clip: text;
  -webkit-text-fill-color: transparent;
}
</style>
