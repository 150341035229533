import HomePage from "@/views/HomePage.vue";
import Partners from "@/views/Partners.vue";
import Policies from "@/views/Policies.vue";

// import EmailActivated from "@/components/connect/EmailActivated.vue";
// import LoginPage from "@/views/PitchConnect/Login.vue";
// import CustomerRegister from "@/components/connect/customer/CustomerRegister.vue";
// import PartnerRegister from "@/components/connect/partner/PartnerRegister.vue";

export const public_routes = [
  {
    path: "/",
    name: "Pitch English Course",
    meta: { public: true },
    component: HomePage,
  },
  {
    path: "/seja-licenciado",
    name: "Professor licenciado",
    meta: { public: true },
    component: Partners,
  },
  {
    path: "/termos",
    name: "Termos",
    meta: { public: true },
    component: Policies,
  },
  /*
  {
    path: "/connect",
    name: "Acesso",
    meta: { public: true },
    component: LoginPage,
  },
  {
    path: "/registro-aluno",
    name: "Registro-aluno",
    meta: { public: true },
    component: CustomerRegister,
  },
  {
    path: "/registro-professor",
    name: "Registro-professor",
    meta: { public: true },
    component: PartnerRegister,
  },
  {
    path: "/confirmar-email",
    name: "Confirmar-email",
    meta: { public: true },
    component: EmailActivated,
  },
  */
];
