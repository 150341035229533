<template>
  <v-btn
    class="floating-button"
    color="primary"
    fab
    dark
    fixed
    bottom
    right
    x-large
    @click="openLink"
  >
    <v-icon x-large>mdi-whatsapp</v-icon>
  </v-btn>
</template>
<script>
export default {
  name: "WhatsappFloatingButton",
  components: {},
  mixins: [],
  props: {},
  data() {
    return {
      link: `https://api.whatsapp.com/send?phone=${this.whatsApp()}&text=Ol%C3%A1%20!%20Vim%20pelo%20site%20do%20curso%20e%20gostaria%20de%20saber%20mais%20informa%C3%A7%C3%B5es!`,
    };
  },
  computed: {},
  watch: {},
  mounted() {},
  methods: {
    openLink() {
      window.open(this.link, "_blank");
    },
    whatsApp() {
      return process.env.VUE_APP_PITCH_WPP.replaceAll(" ", "").replaceAll(
        "-",
        ""
      );
    },
  },
};
</script>
<style>
.floating-button {
  z-index: 9999;
}
</style>
