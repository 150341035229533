export default {
  data() {
    return {
      observers: {},
      visibility: {},
    };
  },
  methods: {
    observeSection(refName, key) {
      const element = this.$refs[refName];
      if (element && !this.observers[key]) {
        this.visibility[key] = false;
        this.observers[key] = new IntersectionObserver(
          (entries) => {
            entries.forEach((entry) => {
              this.debounceVisibilityUpdate(entry, key);
            });
          },
          {
            threshold: 0.3,
          }
        );
        this.observers[key].observe(element);
      }
    },
    debounceVisibilityUpdate(entry, key) {
      clearTimeout(this.visibilityTimeout);
      this.visibilityTimeout = setTimeout(() => {
        this.visibility[key] = entry.isIntersecting;
      }, 5);
    },
    unobserveSection(key) {
      if (this.observers[key]) {
        this.observers[key].disconnect();
        // delete this.observers[key];
      }
    },
  },
  beforeDestroy() {
    Object.keys(this.observers).forEach((key) => this.unobserveSection(key));
  },
};
