<template>
  <v-app>
    <transition name="slide-fade"> <router-view /></transition>

    <SnackBar />
  </v-app>
</template>
<script>
import SnackBar from "@/components/alerts/SnackBar.vue";
export default {
  components: {
    SnackBar,
  },
};
</script>
<style lang="scss">
body {
  @include scrollbar();
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif, Poppins;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  background-color: #f1f1f4;
}

.test {
  @include font-source(2rem, $primary, 800, 2.5rem);
}

.pulse {
  @include pulse-effect($primary);
}

.slide-fade-enter-active {
  transition: all 1s ease;
}
.slide-fade-leave-active {
  transition: all 1s;
}
.slide-fade-enter,
.slide-fade-leave-to {
  transition: opacity 0.8s ease;
}
</style>
