<template>
  <div class="progress-bar-container">
    <div class="progress-bar" :style="{ width: progressWidth + '%' }"></div>
  </div>
</template>

<script>
export default {
  name: "GradientProgressBar",
  props: {
    progressWidth: {
      type: Number,
      default: 100,
    },
  },
  data() {
    return {};
  },
};
</script>

<style scoped>
.progress-bar-container {
  width: 100%;
  height: 1vh;
  background-color: #e0e0e0;
  border-radius: 10px;
  overflow: hidden;
}

.progress-bar {
  height: 100%;
  background: linear-gradient(90deg, #9923cd 0%, #e8276f 75.67%);
  border-radius: 10px 0 0 10px;
  transition: width 1s ease-in-out;
}
</style>
