<template>
  <div class="main">
    <TopMenu @scroll="scroll" id="top" />
    <section id="home" ref="homeSection" style="min-height: 80vh; width: 100vw">
      <Home />
    </section>
    <section
      id="metodologia"
      ref="methodologySection"
      style="min-height: 100vh; width: 100vw"
    >
      <Methodology v-if="visibility.methodology" />
    </section>
    <section
      id="diferencial"
      ref="differentialSection"
      style="min-height: 100vh; width: 100vw"
    >
      <Differential v-if="visibility.differential" />
    </section>
    <section
      id="planos"
      ref="plansSection"
      style="min-height: 100vh; width: 100vw"
    >
      <Plans v-if="visibility.plans" />
    </section>
    <section id="faq" ref="faqSection" style="min-height: 100vh; width: 100vw">
      <FAQ v-if="visibility.faq" />
    </section>
    <WhatsApp />
    <Footer @scrollTo="scroll" />
  </div>
</template>

<script>
import TopMenu from "@/components/homepage/TopMenu.vue";
import Home from "@/components/homepage/Home.vue";
import Methodology from "@/components/homepage/Methodology.vue";
import Differential from "@/components/homepage/Differential.vue";
import Plans from "@/components/homepage/Plans.vue";
import FAQ from "@/components/homepage/FAQ.vue";
import Footer from "@/components/homepage/Footer.vue";
import WhatsApp from "@/components/homepage/WhatsApp.vue";
import IntersectionObserverMixin from "@/mixins/IntersectionObserverMixin.js";

export default {
  name: "HomePage",
  components: {
    TopMenu,
    Home,
    Methodology,
    Differential,
    Plans,
    FAQ,
    Footer,
    WhatsApp,
  },
  mixins: [IntersectionObserverMixin],
  mounted() {
    window.addEventListener("scroll", this.handleScroll);
    this.setupObservers();
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.handleScroll);
  },
  methods: {
    scroll(target) {
      const section = document.getElementById(target);
      scrollTo(0, section.offsetTop - 170);
    },
    handleScroll() {
      this.$forceUpdate();
    },
    setupObservers() {
      this.observeSection("homeSection", "home");
      this.observeSection("methodologySection", "methodology");
      this.observeSection("differentialSection", "differential");
      this.observeSection("plansSection", "plans");
      this.observeSection("faqSection", "faq");
    },
  },
};
</script>

<style scoped></style>
