<template>
  <v-container>
    <v-row class="mt-6">
      <v-col cols="12" md="5" sm="5" class="d-flex align-center justify-start">
      </v-col>
      <v-col cols="12" md="7" sm="7" class="d-flex align-center justify-start">
        <span class="text-h5 bolder" style="color: #5f5f61"
          >Termos & Condições</span
        >
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="5" sm="5" class="d-flex align-start justify-start">
        <v-card elevation="0" style="background-color: transparent">
          <v-card-text>
            <v-row
              dense
              v-for="(item, index) in content"
              :key="index"
              class="mt-2"
            >
              <v-col cols="auto" class="d-flex align-center jutisy-center">
                <v-img
                  src="@/assets/icons/arrow_outline.svg"
                  contain
                  max-height="15px"
                />
              </v-col>
              <v-col>
                <span style="color: #5f5f61" class="bolder">{{
                  item.title
                }}</span>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" md="7" sm="7" class="d-flex align-center justify-start">
        <v-card elevation="0" style="background-color: transparent">
          <v-card-text>
            <v-row v-for="(item, index) in content" :key="index">
              <v-col cols="12" md="11" sm="11">
                <span style="color: #5f5f61" class="bolder"
                  >{{ index + 1 }}.</span
                ><span style="color: #5f5f61" class="bolder text-h7 ml-1"
                  >{{ item.title }}:</span
                ><br />
                <span>{{ item.text }}</span>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
export default {
  name: "EulaSreen",
  components: {},
  mixins: [],
  props: {},
  data() {
    return {
      greetings:
        "Bem-vindo ao Pitch English Course! Ao acessar e utilizar nosso site, você concorda em cumprir e ficar vinculado aos seguintes termos e condições de uso. Leia atentamente as informações abaixo antes de continuar a usar nossos serviços.",
      content: [
        {
          title: "Uso do site",
          text: "Ao utilizar nosso site, você concorda em fazê-lo apenas para fins legais e de acordo com estes termos. Compromete-se a não utilizar o site para qualquer atividade que viole leis locais, estaduais, nacionais ou internacionais.",
        },
        {
          title: "Conteúdo",
          text: "Todo o conteúdo fornecido em nosso site é apenas para fins informativos. Não garantimos a precisão, integridade ou atualidade do conteúdo. Reservamo-nos o direito de modificar, substituir ou excluir qualquer conteúdo a qualquer momento sem aviso prévio.",
        },
        {
          title: "Privacidade",
          text: "Suas informações pessoais são importantes para nós. Consulte nossa Política de Privacidade para entender como coletamos, usamos e protegemos suas informações.",
        },
        {
          title: "Links Externos",
          text: "Nosso site pode conter links para sites de terceiros. Não somos responsáveis pelo conteúdo ou práticas de privacidade desses sites.",
        },
        {
          title: "Alterações nos termos",
          text: "Reservamo-nos o direito de modificar estes termos a qualquer momento. As alterações entrarão em vigor imediatamente após serem publicadas no site.",
        },
        {
          title: "Limitação de Responsabilidade",
          text: "Em nenhuma circunstância seremos responsáveis por danos diretos, indiretos, especiais, incidentais ou consequentes resultantes do uso ou incapacidade de usar nosso site. Ao continuar a utilizar o site da Pitch English, você concorda expressamente com estes termos e condições. Se não concordar com qualquer parte destes termos, por favor, não use o nosso site.",
        },
      ],
    };
  },
  computed: {},
  watch: {},
  mounted() {},
  methods: {},
};
</script>
<style scoped>
.bolder {
  font-weight: bold;
}
</style>
