import { render, staticRenderFns } from "./CustomFileInput.vue?vue&type=template&id=8db87b80&scoped=true"
import script from "./CustomFileInput.vue?vue&type=script&lang=js"
export * from "./CustomFileInput.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8db87b80",
  null
  
)

export default component.exports