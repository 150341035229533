<template>
  <div class="fill-width">
    <div v-if="!!label" class="text-body-2 mb-1">{{ label }}</div>
    <v-file-input
      counter
      multiple
      show-size
      small-chips
      truncate-length="15"
    ></v-file-input>
  </div>
</template>
<script>
export default {
  name: "CustomFileInput",
  props: {
    label: {
      type: String,
      required: false,
    },
  },
  data() {
    return {};
  },
  methods: {},
};
</script>
<style scoped></style>
